import React from 'react';
import { useSelector } from 'react-redux';
import { useLabels } from 'wsm-common-data';
import { getHtmlWithDLData } from '../utils/getHtmlWithDLData';
import { selectCurrentItem } from '../reducers/items-selectors';

const TabText = () => {
	const labels = useLabels();
	const activeItem = useSelector(selectCurrentItem);

	return (
		<div style={{ padding: '10px', textAlign: 'justify' }}>
			{activeItem?.heading && (
				<h2>{getHtmlWithDLData(labels.get(activeItem.heading))}</h2>
			)}
			{activeItem?.content &&
				getHtmlWithDLData(labels.get(activeItem.content))}
		</div>
	);
};

export default TabText;
